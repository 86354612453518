import React from "react"
import Layout from "../layout/layout"
const AboutPage=() =>{
    return(
        <Layout title="About">
            <h1>The Secret Blog!</h1>
            <p>An <b>Ad-Free</b> blog</p>
            <p>Just started the blog in a different way to provide old/new developers to explore the unexplored technologies, it could contain something you may know or may not.</p>
            <p>A lot of new developers struggles at the beginning and miss-out the basic concepts and useful corners</p>
            <p>So, everything you read in here, it's been from my self-learned personal experiences, and written after a lot of research and experiments, to put it on a paper in a simple way.</p>
            <p>You can follow the blog at <a href="https://linkedin.com/company/rahul3v" target="_blank">Linkedin</a> for any new updates</p>
            <p>A lot of things are just cooking in my mind, but it's really difficult to explain it in plain and simple words. But I'm trying my best and if you think it helps you a lot in the great journey of your career, please consider supporting what I do with <a href="https://www.patreon.com/rahul3v" targrt="_blank">Patreon</a></p>
        </Layout>
    )
}
export default AboutPage;